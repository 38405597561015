"use client";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import useTranslationAPI from "@/lib/hooks/useTranslationApi";

import "swiper/css";
import "swiper/css/effect-fade";

export default function HeaderTopLeft() {
	const { t } = useTranslationAPI(["header_top_left_1", "header_top_left_2"]);
	const data = [
		{
			text: t("header_top_left_1"),
		},
		{
			text: t("header_top_left_2"),
		},
	];
	return (
		<Swiper
			spaceBetween={30}
			initialSlide={2}
			className="w-[100%] text-center text-white text-sm md:w-[50%] md:text-gray-500 md:text-base md:text-left"
			slidesPerView={2}
			effect="fade"
			// allowSlideNext={false}
			allowTouchMove={false}
			// allowSlidePrev={false}
			// enabled={false}
			loop
			autoplay={{
				delay: 2000,
				disableOnInteraction: false,
			}}
			fadeEffect={{ crossFade: true }}
			modules={[Autoplay, EffectFade]}>
			{data.map((item, index) => {
				return (
					<SwiperSlide key={index}>
						<p className="w-full h-full text-sm text-white lg:text-gray-500 leading-[16px] lg:text-base lg:text-left  text-center flex justify-center xl:justify-start items-center  ">
							{item.text}
						</p>
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
}
